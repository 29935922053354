// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import MediaKit from "../components/mediaKit"
// --- end common

import BannerSection from "../components/bannerSection"
import MultiUp from "../components/multiUp"
import MultiUpItem from "../components/multiUpItem"

// Import images
import bannerImage from "../images/pbs.svg"

import scienceLeadImage from "../images/TV_Science.png"
import historyLeadImage from "../images/TV_History.png"
import dramaLeadImage from "../images/TV_Drama.png"
import kidsLeadImage from "../images/PBS-WGBH-Childrens-programming-Curious-Geroge-sponsorship.png"
import newsLeadImage from "../images/PBS-WGBH-FRONTLINE-Capitol-Building-investigative-journalism-sponsorship.jpg"
import lifeLeadImage from "../images/PBS-WGBH-Lifestyle-Simply-Ming-Sponsorship.jpg"

const scienceHeader = {
  title: "Science & Nature",
  image: scienceLeadImage,
}
const historyHeader = {
  title: "History & Documentaries",
  image: historyLeadImage,
}
const dramaHeader = {
  title: "Drama & Performance",
  image: dramaLeadImage,
}
const kidsHeader = {
  title: "Children's Programming",
  image: kidsLeadImage,
}
const newsHeader = {
  title: "News and Public Affairs",
  image: newsLeadImage,
}
const lifeHeader = {
  title: "Lifestyle",
  image: lifeLeadImage,
}

const TelevisionPage = () => (
  <Layout>
    <Seo
      title="Television"
      isTitleExtended={true}
      description="History, drama, news, science and children's programming—when it comes to high-quality, award-winning television, GBH has the best sponsorship opportunities."
    />

    <BannerSection
      category="tv"
      header="Stand Out on PBS’s Unmatched Content"
      subHeader="When it comes to high-quality, award-winning television, GBH has the best sponsorship opportunities on TV."
      image={bannerImage}
    />

    <div className="clearAll"></div>

    <div className="list-section">
      <MultiUp type="three-up">
        <MultiUpItem header={scienceHeader} />
        <MultiUpItem header={historyHeader} />
        <MultiUpItem header={dramaHeader} />
      </MultiUp>

      <MultiUp type="three-up">
        <MultiUpItem header={kidsHeader} />
        <MultiUpItem header={newsHeader} />
        <MultiUpItem header={lifeHeader} />
      </MultiUp>
    </div>
    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default TelevisionPage
